<template>
	<div>
		<Header title="查看结算账户" @back="onBack"></Header>
        <a-spin :spinning="loading">
            <a-form disable style="margin-top: 30px;" ref="formRef" scrollToFirstError name="form" :labelCol="{span: 6, xxl: 5}"
                :wrapperCol="{span: 14, xxl: 13 }">
                
                <a-form-item label="用户类型" name="type" :rules="[{required: true, message: '必选项不允许为空'}]">
                    <a-radio-group v-model:value="formState.type" disabled button-style="solid">
                        <a-radio-button :value="1">个人用户</a-radio-button>
                        <a-radio-button :value="2">企业用户</a-radio-button>
                    </a-radio-group>
                </a-form-item>

                <a-form-item label="分账类型" name="useType" :rules="[{required: true, message: '必选项不允许为空'}]">
                    <a-radio-group disabled v-model:value="formState.useType" button-style="solid">
                        <a-radio-button style="width: 88px;text-align: center;" :value="2">全局</a-radio-button>
                    </a-radio-group>
                </a-form-item>

                <div v-if="formState.type == 1">
                    <a-form-item label="用户姓名" :rules="[{required: true, message: '必填项不允许为空'}]">
                        <div>{{formState.userName}}</div>
                    </a-form-item>

                    <a-form-item label="用户手机号" name="telNo" :rules="[{required: true, message: '必填项不允许为空'}]">
                        <div>{{formState.telNo}}</div>
                    </a-form-item>

                    <a-form-item label="性别">
                        <a-radio-group disabled v-model:value="formState.gender">
                            <a-radio value="MALE">男</a-radio>
                            <a-radio value="FEMALE">女</a-radio>
                        </a-radio-group>
                    </a-form-item>

                    <a-form-item label="邮箱">
                        <div>{{formState.email || '-'}}</div>
                    </a-form-item>

                    <a-form-item label="身份证号码" name="certId" :rules="[{required: true, message: '必填项不允许为空'}]">
                        <div>{{formState.certId}}</div>
                    </a-form-item>
                </div>

                <div v-else>
                    <a-form-item label="企业名称" name="corpName" :rules="[{required: true, message: '必填项不允许为空'}]">
                        <div>{{formState.corpName}}</div>
                    </a-form-item>

                    <a-form-item label="统一社会信用码" name="socialCreditCode" :rules="[{required: true, message: '必填项不允许为空'}]">
                        <div>{{formState.socialCreditCode}}</div>
                    </a-form-item>

                    <a-form-item label="统一社会信用证有效期" name="vaildTime" :rules="[{required: true, message: '必填项不允许为空'}]">
                        <a-row>
                            <a-range-picker v-model:value="formState.vaildTime" />
                            <div style="margin-left: 10px;"> 截止: <a-radio disabled :value="1">长期</a-radio></div>
                        </a-row>
                    </a-form-item>

                    <a-form-item label="经营范围" name="businessScope" :rules="[{required: true, message: '必填项不允许为空'}]">
                        <div>{{formState.businessScope}}</div>
                    </a-form-item>

                    <a-form-item label="法人姓名" name="userName" :rules="[{required: true, message: '必填项不允许为空'}]">
                        <div>{{formState.userName}}</div>
                    </a-form-item>

                    <a-form-item label="法人身份证号码" name="certId" :rules="[{required: true, message: '必填项不允许为空'}]">
                        <div>{{formState.certId}}</div>
                    </a-form-item>

                    <a-form-item label="法人身份证有效期" name="certVaildTime" :rules="[{required: true, message: '必填项不允许为空'}]">
                        <a-range-picker v-model:value="formState.certVaildTime" />
                    </a-form-item>

                    <a-form-item label="法人手机号" name="telNo" :rules="[{required: true, message: '必填项不允许为空'}]">
                        <div>{{formState.telNo}}</div>
                    </a-form-item>

                    <a-form-item label="企业地址" name="location" :rules="[{required: true, message: '必填项不允许为空'}]">
                        <div>{{formState.location}}</div>
                    </a-form-item>

                    <!-- <a-form-item label="企业电话">
                        <div>{{formState.telNo}}</div>
                    </a-form-item> -->

                    <a-form-item label="企业邮箱">
                        <div>{{formState.email || '-'}}</div>
                    </a-form-item>

                    <a-form-item label="上传附件" :rules="[{required: true, message: '必填项不允许为空'}]" extra="注：上传附件，内容须包含三证合一证件照、法人身份证正面照、法人身份证反面照、开户银行许可证照。 压缩 zip包后上传，最大限制为 9 M。">
                        <a-upload disabled
                            v-model:file-list="fileList"
                            list-type="picture-card"
                        >
                            <div v-if="fileList.length < 1">
                                <Icon icon="PlusOutlined"></Icon>
                                <div style="margin-top: 8px">Upload</div>
                            </div>
                        </a-upload>
                    </a-form-item>
                </div>

                
                <a-form-item label="结算银行卡号" name="cardId" :rules="[{required: true, message: '必填项不允许为空'}]">
                    <div>{{formState.cardId}}</div>
                </a-form-item>

                <a-form-item label="银行卡户名" name="cardName" :rules="[{required: true, message: '必填项不允许为空'}]" extra="注：需填写银行卡对应的户名。若银行账户类型是对公，必须与企业名称一致">
                    <div>{{formState.cardName}}</div>
                </a-form-item>
                
                <div v-if="formState.type == 1">
                    <a-form-item label="选择银行">
                        <div v-for="bankItem in bankCodeData">
                            <div v-if="bankItem.value == formState.bankCode">{{bankItem.label}}</div>
                        </div>
                    </a-form-item>

                    <a-form-item class="ui-form__item" label="账户开户银行所在省份">
                        <div v-for="provItem in provCodeData">
                            <div v-if="provItem.value == formState.provCode">{{provItem.label}}</div>
                        </div>
                    </a-form-item>

                    <a-form-item class="ui-form__item" label="账户开户银行所在地区">
                        <div v-for="areaItem in areaCodeData">
                            <div v-if="areaItem.value == formState.areaCode">{{areaItem.label}}</div>
                        </div>
                    </a-form-item>
                </div>
                <div v-else>
                    <a-form-item label="选择银行" :rules="[{required: true, message: '必填项不允许为空'}]">
                        <div v-for="bankItem in bankCodeData">
                            <div v-if="bankItem.value == formState.bankCode">{{bankItem.label}}</div>
                        </div>
                    </a-form-item>

                    <a-form-item class="ui-form__item" label="账户开户银行所在省份" :rules="[{required: true, message: '必填项不允许为空'}]">
                        <div v-for="provItem in provCodeData">
                            <div v-if="provItem.value == formState.provCode">{{provItem.label}}</div>
                        </div>
                    </a-form-item>

                    <a-form-item class="ui-form__item" label="账户开户银行所在地区" :rules="[{required: true, message: '必填项不允许为空'}]">
                        <div v-for="areaItem in areaCodeData">
                            <div v-if="areaItem.value == formState.areaCode">{{areaItem.label}}</div>
                        </div>
                    </a-form-item>
                </div>
                <a-row>
                    <a-col :span="24" style="margin-top: 20px;text-align: center;">
                        <a-button style="margin-left: 20px;" @click="onBack">返回</a-button>
                    </a-col>
                </a-row>
            </a-form>
        </a-spin>
	</div>
</template>

<script>
	import Header from '@/components/header/header.vue';
    import { Icon } from '@/components/icon/icon.js';
	import {ledgerDetail} from '@/service/modules/ledger.js';
    import bankCode from '@/assets/bank/bankCode.json';
    import provCode from '@/assets/province/provCode.json';
	export default {
		components: { Header, Icon },
		props: {
			isEdit: {
				type: Boolean,
				default: true
			},
			id: {
				type: Number,
				default: 0
			},
		},
		created() {
            this.getData();
		},
		data() {
			return {
                fileList: [],
                formState: {
                    type: 1,
                    certVaildTime: '',
                    vaildTime: '',
                    gender: 'MALE',
                    useType: 2,
                },
                bankCodeData: [],
				provCodeData: [],
                areaCodeData: [],
                loading: false,
			}
		},
		methods: {
			onBack() {
				this.$emit('back');
			},
			async getData() {
                this.loading = true;
                try {
                    let {data} = await ledgerDetail({
                        id: this.id,
                    })
                    this.loading = false;
                    this.formState = data;
                    this.bankCodeData = bankCode;
                    this.provCodeData = provCode;
                    provCode.forEach(item=>{
                        if(item.value == this.formState.provCode){
                            this.areaCodeData = item.cities;
                        }
                    })
                    if(data.attachFileUrl){
                        const fileList = [{
                            url: data.attachFileUrl.split(',')[0],
                        }]
                        this.fileList = fileList;
                    }
                } catch (error) {
                    this.loading = false;
                }
            },
			
		}
	}
</script>

<style scoped></style>
