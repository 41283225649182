<template>
	<div v-if="!showModal">
		<a-spin :spinning="loading">
			<a-form ref="formRef" :model="formState" name="form" @finish="onSearch">
				<a-row>
					<a-form-item class="ui-form__item" label="企业/个人用户信息" name="userInfo">
                        <a-input placeholder="手机号/姓名/昵称" v-model:value="formState.userInfo"></a-input>
					</a-form-item>

					<a-form-item class="ui-form__item" label="分账身份" name="useType">
						<a-select placeholder="请选择" v-model:value="formState.useType" style="width: 180px;">
							<a-select-option :value="1">分销</a-select-option>
							<a-select-option :value="2">全局</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" label="用户类型" name="type">
						<a-select placeholder="请选择" v-model:value="formState.type" style="width: 180px;">
							<a-select-option :value="1">个人用户</a-select-option>
							<a-select-option :value="2">企业用户</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" label="是否实名" name="identified">
						<a-select placeholder="请选择" v-model:value="formState.identified" style="width: 180px;">
							<a-select-option value="Y">是</a-select-option>
							<a-select-option value="N">否</a-select-option>
						</a-select>
					</a-form-item>

                </a-row>

				<a-row>
                    <a-col :span="18">
						<a-button v-permission="['ledger_manage_list_add']" type="primary" @click="onAdd">新增</a-button>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="onReset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 20px;">
				<a-table :pagination="pagination" rowKey="id" :columns="columns" :dataSource="list" tableLayout="auto" :scroll="{x: 1300}">
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key === 'type'">
							<div v-if="record.type == 1">个人</div>
							<div v-else>企业</div>
						</template>

						<template v-if="column.key === 'useType'">
							<div>{{transUserType(record.useType)}}</div>
						</template>

						<template v-if="column.key === 'corpName'">
							<div v-if="record.corpName">{{record.corpName}}</div>
							<div v-else>--</div>
						</template>

						<template v-if="column.key === 'identified'">
							<div v-if="record.identified == 'Y'">是</div>
							<div v-else>否</div>
						</template>

            <template v-if="column.key === 'auditState'">
              <div v-if="record.auditState == 'A'">待审核</div>
              <div v-else-if="record.auditState == 'B'">审核失败</div>
              <div v-else-if="record.auditState == 'C'">开户失败</div>
              <div v-else-if="record.auditState == 'D'">开户成功但未创建结算账户</div>
              <div v-else-if="record.auditState == 'E'">开户和创建结算账户成功</div>
              <div v-else>-</div>
            </template>

						<template v-if="column.key === 'createTime'">
							{{ transDateTime(record.createTime) }}
						</template>

						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
								</a-button>
								<template #overlay>
									<a-menu>
										<div v-if="record.useType == 2 && record.disabled == 'N'" v-permission="['ledger_manage_list_disabled']">
											<a-menu-item @click="onDisabled(record)"> 禁用 </a-menu-item>
										</div>
										<div v-if="record.useType == 2 && record.disabled == 'Y'" v-permission="['ledger_manage_list_enabled']">
											<a-menu-item @click="onDisabled(record)"> 启用 </a-menu-item>
										</div>
										<div v-permission="['ledger_manage_list_modify']">
											<a-menu-item @click="onEdit(record)"> 修改结算账户 </a-menu-item>
										</div>
										<div v-permission="['ledger_manage_list_detail']">
											<a-menu-item @click="onDetail(record)"> 查看详情 </a-menu-item>
										</div>
                    <div v-permission="['ledger_manage_list_modify']" v-if="record.auditState == 'B' || record.auditState == 'C'">
                      <a-menu-item @click="onDelete(record)"> 删除 </a-menu-item>
                    </div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
				</a-table>
			</div>
			<a-modal v-model:visible="visibleEdit" width="550px" title="修改结算账户" :footer="null">
				<a-form ref="form" :model="formEdit" name="form" @finish="onSubmit">
					<a-form-item v-if="formEdit.type == 1" class="ui-form__item" label="身份证号码" name="certId" :rules="[{required: true, message: '必填项不允许为空'}]">
						<a-input placeholder="请输入" v-model:value="formEdit.certId"></a-input>
					</a-form-item>

					<a-form-item class="ui-form__item" label="结算银行卡号" name="cardId" :rules="[{required: true, message: '必填项不允许为空'}]">
						<a-input placeholder="请输入" v-model:value="formEdit.cardId"></a-input>
					</a-form-item>

					<a-form-item class="ui-form__item" label="银行卡户名" name="cardName" :rules="[{required: true, message: '必填项不允许为空'}]">
						<a-input placeholder="请输入" v-model:value="formEdit.cardName"></a-input>
					</a-form-item>

					<a-form-item label="选择银行" name="bankCode">
						<a-select v-model:value="formEdit.bankCode" placeholder="请选择银行" :options="bankCodeData"
							optionLabelProp="label" showSearch @change="onChooseBank" :filterOption="selectInput"
						></a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" label="开户银行所在省份" name="provCode">
						<a-select v-model:value="formEdit.provCode" placeholder="请选择" :options="provCodeData"
                        optionLabelProp="label" showSearch @change="onChooseProvince" :filterOption="selectProvCode"> </a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" label="开户银行所在地区" name="areaCode">
						<a-select v-model:value="formEdit.areaCode" placeholder="请选择" :options="areaCodeData"
                        optionLabelProp="label" showSearch @change="onChooseArea" :filterOption="selectAreaCode">
                    	</a-select>
					</a-form-item>
					<a-row>
						<a-col :span="24" style="margin-top: 20px;text-align: center;">
							<a-button type="primary" html-type="submit">提交</a-button>
							<a-button style="margin-left: 20px;" @click="onCancel">取消</a-button>
						</a-col>
					</a-row>
				</a-form>
			</a-modal>
		</a-spin>
	</div>
    <temp v-else :isEdit="isEdit" :isDetail="isDetail" :id="id" @back="onBack"></temp>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import {getLedgerUserList, updateLedger,disabledLedger,enableLedger,delAdapayMember} from '@/service/modules/ledger.js';
    import temp from './temp.vue';
    import bankCode from '@/assets/bank/bankCode.json';
    import provCode from '@/assets/province/provCode.json';

	export default {
		components: {
			Icon,
            temp
		},
		data() {
			return {
				loading: false,
				formState: {},
				showModal: false,
				list: [],
				columns: [{
					title: '用户类型',
					dataIndex: 'type',
					key: 'type'
				}, {
					title: '分账身份',
					dataIndex: 'useType',
					key: 'useType'
				}, {
					title: '企业名称',
					key: 'corpName'
				}, {
					title: '企业用户法人姓名/个人用户姓名',
					dataIndex: 'userName'
				}, {
					title: '企业用户法人手机号/个人用户手机号',
					dataIndex: 'telNo',
					key: 'telNo'
				}, {
					title: '是否实名',
					key: 'identified'
				}, {
					title: '审核状态',
					key: 'auditState'
				}, {
					title: '创建时间',
					key: 'createTime',
					width: 180
				},{
					title: '操作',
					key: 'action',
					width: 100,
					fixed: 'right'
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				isEdit: false,
        isDetail: false,
				id: 0,
				searchData: [],
				visibleEdit: false,
				formEdit: {},
				bankCodeData: [],
				provCodeData: [],
                areaCodeData: [],
			}
		},
		created(){
			this.getData();
		},
		methods: {
			onBack() {
				this.showModal = false;
				this.getData();
			},
			onSearch() {
				this.pagination.current = 1;
				// //this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.getData();
			},
			onReset() {
				this.$refs.formRef.resetFields();
				this.onSearch();
			},
			async getData() {
				const postData = {
					page: this.pagination.current,
					pageSize: this.pagination.pageSize,
					...this.searchData,
				}
				this.loading = true;
				try{
					let res = await getLedgerUserList(postData);
					if(res.code == 200){
						this.loading = false;
						this.list = res.data.list;
						this.pagination.total = res.data.totalCount;
					}
				}catch(e){
					this.loading = false;
				}
			},
			onAdd() {
				this.id = 0;
				this.isEdit = false;
				this.showModal = true;
				this.isDetail = false;
			},
			onEdit(val) {
        if (val.type == 1) {
          // 个人
          let provValue;
          this.bankCodeData = bankCode;
          this.provCodeData = provCode;
          this.list.forEach(item=>{
            if(item.id == val.id){
              this.formEdit = item;
              provValue = item.provCode;
            }
          })
          provCode.forEach(item=>{
            if(item.value == provValue){
              this.areaCodeData = item.cities;
            }
          })
          this.visibleEdit = true;
        } else {
          // 企业
          this.$message.warn('企业账户不能编辑，未通过审核的账户请执行删除操作');
          // this.id = val.id;
          // this.isEdit = true;
          // this.isDetail = false;
          // this.showModal = true;
        }

			},
			onDetail(val){
				this.id = val.id;
				this.isEdit = false;
				this.isDetail = true;
				this.showModal = true;
			},
			onDisabled(val){
				let text = val.disabled == 'Y' ? '启用' : '禁用';
				this.$confirm({
					title: 'warning',
					content: '正在进行' + text + '操作',
					okText: '确定',
					cancleText: '取消',
					onOk: ()=>{
						if(val.disabled == 'N'){
							this.disableUser(val.id);
						}else{
							this.enableUser(val.id);
						}
					}
				})
			},
			transUserType(type){
				const typeObj = {
					1: '分销',
					2: '全局',
					3: '影院'
				}
				return typeObj[type];
			},
			async disableUser(id){
				let res = await disabledLedger({id});
				if(res.code == 200){
					this.getData();
					this.$message.success('禁用成功');
				}
			},
			async enableUser(id){
				let res = await enableLedger({id});
				if(res.code == 200){
					this.getData();
					this.$message.success('启用成功');
				}
			},
			onChooseBank(value){
                this.formEdit.bankCode = value;
            },
			selectInput(inputValue, option){
                return (
                    option.label.indexOf(inputValue.toLowerCase()) >= 0
                );
            },
			onCancel() {
				this.visibleEdit = false;
				this.$refs.form.resetFields();
			},
			onSubmit() {
                this.$refs.form.validateFields().then(async () => {
                    const postData = {
						adapayMemberId : this.formEdit.id,
						memberId : this.formEdit.memberId,
						bankAcctType : this.formEdit.type == 1 ? 2 : 1,//1-对公；2-对私
						cardId: this.formEdit.cardId,
						cardName: this.formEdit.cardName,
						telNo: this.formEdit.telNo,
						bankCode: this.formEdit.bankCode,
						provCode: this.formEdit.provCode,
						areaCode: this.formEdit.areaCode
					};
					if(this.formEdit.type == 1){
						postData.certId = this.formEdit.certId;
					}
                    let res = await updateLedger(postData);
                    if(res.code == 200){
                        this.$message.success('修改成功');
						this.visibleEdit = false;
                    }
				})
      },
      onDelete(val){
        if (val.auditState == 'B' || val.auditState == 'C') {
          this.$confirm({
            title: '提示',
            content: '是否删除账户',
            onOk: () => {
              this.loading = true;
              delAdapayMember({ id: val.id }).then(res=>{
                this.loading = false;
                if (res.code == 200) {
                  this.getData();
                  this.$message.success('删除成功');
                }
              }).catch(err=>{
                this.$message.error('删除失败');
                this.loading = false;
              });
            }
          })
        }
			},
			async deleteLedger(id){
				// let res = await deleteLedger({ id });
				// if(res.code == 200){
				// 	this.getData();
				// 	this.$message.success('删除成功');
				// }
			},
			onChooseProvince(value,obj){
				this.formEdit.areaCode = '';
				this.areaCodeData = [];
                this.formState.provCode = value;
                this.areaCodeData = obj.cities;
            },
            selectProvCode(inputValue, option){
                return (
                    option.label.indexOf(inputValue.toLowerCase()) >= 0
                );
            },
            onChooseArea(value,obj){
                this.formState.areaCode = value;
            },
            selectAreaCode(inputValue, option){
                return (
                    option.label.indexOf(inputValue.toLowerCase()) >= 0
                );
            },
		}
	}
</script>

<style>
	.ui-form__item {
		margin-right: 30px;
	}
</style>
