<template>
	<div v-if="!isDetail">
		<Header :title="`${isEdit == true ? '编辑' : '新增'}结算账户`" @back="onBack"></Header>
		<a-form disable style="margin-top: 30px;" ref="formRef" scrollToFirstError name="form" :labelCol="{span: 6, xxl: 5}"
			:wrapperCol="{span: 14, xxl: 13 }"  @finish="onSubmit" :model="formState">

            <a-form-item label="用户类型" name="type" :rules="[{required: true, message: '必选项不允许为空'}]">
                <a-radio-group v-model:value="formState.type" button-style="solid" :disabled="id">
                    <a-radio-button :value="1">个人用户</a-radio-button>
                    <a-radio-button :value="2">企业用户</a-radio-button>
                </a-radio-group>
            </a-form-item>

            <a-form-item label="分账类型" name="useType" :rules="[{required: true, message: '必选项不允许为空'}]">
                <a-radio-group v-model:value="formState.useType" button-style="solid">
                    <a-radio-button style="width: 88px;text-align: center;" :value="2">全局</a-radio-button>
                </a-radio-group>
            </a-form-item>

            <div v-if="formState.type == 1">
                <a-form-item label="用户姓名" name="userName" :rules="[{required: true, message: '必填项不允许为空'}]">
                    <a-input v-model:value="formState.userName" placeholder="请输入姓名"></a-input>
                </a-form-item>

                <a-form-item label="用户手机号" name="telNo" :rules="[{required: true, message: '必填项不允许为空'}]">
                    <a-input v-model:value="formState.telNo" placeholder="请输入手机号" :maxlength="11"></a-input>
                </a-form-item>

                <a-form-item label="性别">
                    <a-radio-group v-model:value="formState.gender">
                        <a-radio value="MALE">男</a-radio>
                        <a-radio value="FEMALE">女</a-radio>
                    </a-radio-group>
                </a-form-item>

                <a-form-item label="邮箱">
                    <a-input v-model:value="formState.email" placeholder="请输入邮箱"></a-input>
                </a-form-item>

                <a-form-item label="身份证号码" name="certId" :rules="[{required: true, message: '必填项不允许为空'}]">
                    <a-input v-model:value="formState.certId" placeholder="请输入身份证号码" :maxlength="18"></a-input>
                </a-form-item>
            </div>

            <div v-else>
                <a-form-item label="企业名称" name="corpName" :rules="[{required: true, message: '必填项不允许为空'}]">
                    <a-input v-model:value="formState.corpName" placeholder="请输入企业名称"></a-input>
                </a-form-item>

                <!-- <a-form-item label="选择省份" name="provCode" :rules="[{required: true, message: '必填项不允许为空'}]">
                    <a-select placeholder="请选择省份" v-model:value="formState.areaCode">
                        <a-select-option :value="1">广东省</a-select-option>
                    </a-select>
                </a-form-item>

                <a-form-item label="选择地区" name="areaCode" :rules="[{required: true, message: '必填项不允许为空'}]">
                    <a-select  placeholder="请选择地区" v-model:value="formState.areaCode">
                        <a-select-option :value="1">广州</a-select-option>
                    </a-select>
                </a-form-item> -->

                <a-form-item label="统一社会信用码" name="socialCreditCode" :rules="[{required: true, message: '必填项不允许为空'}]">
                    <a-input v-model:value="formState.socialCreditCode" placeholder="请输入统一社会信用码"></a-input>
                </a-form-item>

                <a-form-item label="统一社会信用证有效期" :rules="[{required: true, message: '必填项不允许为空'}]">
                    <a-row style="display: flex;align-items: center;">
                        <a-date-picker v-model:value="formState.socialCreditCodeExpires" @change="onVaildTime"/>
                        <div style="margin-left: 10px;"> 截止: <a-radio v-model:checked="longTime" @focus="onFocus">长期</a-radio></div>
                    </a-row>
                </a-form-item>

                <a-form-item label="经营范围" name="businessScope" :rules="[{required: true, message: '必填项不允许为空'}]">
                    <a-input v-model:value="formState.businessScope" placeholder="请输入经营范围"></a-input>
                </a-form-item>

                <a-form-item label="法人姓名" name="userName" :rules="[{required: true, message: '必填项不允许为空'}]">
                    <a-input v-model:value="formState.userName" placeholder="请输入法人姓名"></a-input>
                </a-form-item>

                <a-form-item label="法人身份证号码" name="certId" :rules="[{required: true, message: '必填项不允许为空'}]">
                    <a-input v-model:value="formState.certId" placeholder="请输入法人身份证号码"></a-input>
                </a-form-item>

                <a-form-item label="法人身份证有效期" name="legalCertIdExpires" :rules="[{required: true, message: '必填项不允许为空'}]">
                    <a-date-picker v-model:value="formState.legalCertIdExpires" @change="onCertVaildTime"/>
                </a-form-item>

                <a-form-item label="法人手机号" name="telNo" :rules="[{required: true, message: '必填项不允许为空'}]">
                    <a-input v-model:value="formState.telNo" placeholder="请输入法人手机号" :maxlength="11"></a-input>
                </a-form-item>

                <a-form-item label="企业地址" name="location" :rules="[{required: true, message: '必填项不允许为空'}]">
                    <a-input v-model:value="formState.location" placeholder="请输入企业地址"></a-input>
                </a-form-item>

                <!-- <a-form-item label="企业电话">
                    <a-input v-model:value="formState.telNo" placeholder="请输入企业电话"></a-input>
                </a-form-item> -->

                <a-form-item label="企业邮箱">
                    <a-input v-model:value="formState.email" placeholder="请输入企业邮箱"></a-input>
                </a-form-item>

                <a-form-item label="上传附件" :rules="[{required: true, message: '必填项不允许为空'}]">
                    <a-upload
                        v-model:file-list="fileList"
                        list-type="picture-card"
                        action="/admin/ajaxUpload.do"
                        :before-upload="beforeUpload"
                        @change="handleChange"
                    >
                        <div v-if="fileList.length < 1">
                            <Icon icon="PlusOutlined"></Icon>
                            <div style="margin-top: 8px">Upload</div>
                        </div>
                    </a-upload>
                    <div class="ui-tip">注：上传附件，内容须包含法人身份证正面照、法人身份证反面照、营业执照以及开户银行许可证照。 压缩 zip包后上传，最大限制为 9 M。</div>
                </a-form-item>
            </div>


            <a-form-item label="结算银行卡号" name="cardId" :rules="[{required: true, message: '必填项不允许为空'}]">
                <a-input v-model:value="formState.cardId" placeholder="请输入结算银行卡号"></a-input>
            </a-form-item>

            <a-form-item label="银行卡户名" name="cardName" :rules="[{required: true, message: '必填项不允许为空'}]">
                <a-input v-model:value="formState.cardName" placeholder="请输入银行卡户名"></a-input>
                <div class="ui-tip">注：需填写银行卡对应的户名。若银行账户类型是对公，必须与企业名称一致</div>
            </a-form-item>

            <div v-if="formState.type == 1">
                <a-form-item label="选择银行">
                    <a-select v-model:value="formState.bankCode" placeholder="请选择银行" :options="bankCodeData"
                        optionLabelProp="label" showSearch @change="onChooseBank" :filterOption="selectBankCode"
                    ></a-select>
                </a-form-item>

                <a-form-item label="账户开户银行所在省份">
                    <a-select v-model:value="formState.provCode" placeholder="请选择银行账户开户银行所在省份" :options="provCodeData"
                        optionLabelProp="label" showSearch @change="onChooseProvince" :filterOption="selectProvCode"> </a-select>
                </a-form-item>

                <a-form-item label="账户开户银行所在地区">
                    <a-select v-model:value="formState.areaCode" placeholder="请选择银行账户开户银行所在地区" :options="areaCodeData"
                        optionLabelProp="label" showSearch @change="onChooseArea" :filterOption="selectAreaCode">
                    </a-select>
                </a-form-item>

            </div>
            <div v-else>
                <a-form-item label="选择银行">
                    <a-select v-model:value="formState.bankCode" placeholder="请选择银行" :options="bankCodeData"
                        optionLabelProp="label" showSearch @change="onChooseBank" :filterOption="selectBankCode"
                    ></a-select>
                </a-form-item>

                <a-form-item label="账户开户银行所在省份" name="provCode" :rules="[{required: true, message: '必填项不允许为空'}]">
                    <a-select v-model:value="formState.provCode" placeholder="请选择银行账户开户银行所在省份" :options="provCodeData"
                        optionLabelProp="label" showSearch @change="onChooseProvince" :filterOption="selectProvCode"> </a-select>
                </a-form-item>

                <a-form-item label="账户开户银行所在地区" name="areaCode" :rules="[{required: true, message: '必填项不允许为空'}]">
                    <a-select v-model:value="formState.areaCode" placeholder="请选择银行账户开户银行所在地区" :options="areaCodeData"
                        optionLabelProp="label" showSearch @change="onChooseArea" :filterOption="selectAreaCode">
                    </a-select>
                </a-form-item>

            </div>
			<a-row>
				<a-col :span="24" style="margin-top: 20px;text-align: center;">
					<a-button type="primary" html-type="submit">提交</a-button>
					<a-button style="margin-left: 20px;" @click="onBack">返回</a-button>
				</a-col>
			</a-row>
		</a-form>
	</div>
    <Detail v-else :id="id" @back="onBack"></Detail>
</template>

<script>
	import Header from '@/components/header/header.vue';
    import { Icon } from '@/components/icon/icon.js';
    import moment from 'moment';
    import Detail from './detail.vue';
	import {saveLedger, ledgerDetail, updateCorpMember} from '@/service/modules/ledger.js';
    import bankCode from '@/assets/bank/bankCode.json';
    import provCode from '@/assets/province/provCode.json';
	export default {
		components: { Header, Icon , Detail },
		props: {
			isEdit: {
				type: Boolean,
				default: true
			},
			id: {
				type: Number,
				default: 0,
        immedate: true
			},
      isDetail: {
        type: Boolean,
        default: true
      },
		},
		created() {
            this.bankCodeData = bankCode;
            this.provCodeData = provCode;
      if (this.id && !this.isDetail) {
        this.getData();
      }
		},
    watch: {
      id(newVal){
        this.getData();
      },
    },
		data() {
			return {
                fileList: [],
                formState: {
                    type: 1,
                    useType: 2,
                },
                longTime: false,
                bankCodeData: [],
                provCodeData: [],
                areaCodeData: [],
			}
		},
		methods: {
			onBack() {
				this.$emit('back');
			},
      async getData() {
        this.loading = true;
        try {
          let {data} = await ledgerDetail({
            id: this.id,
          })
          this.loading = false;
          if (data.socialCreditCodeExpires == '20991231') {
            this.longTime = true;
            data.socialCreditCodeExpires = null;
          } else {
            data.socialCreditCodeExpires = moment(data.socialCreditCodeExpires, 'YYYYMMDD');
          }
          data.legalCertIdExpires = moment(data.legalCertIdExpires, 'YYYYMMDD');
          this.formState = data;
          this.bankCodeData = bankCode;
          this.provCodeData = provCode;
          provCode.forEach(item=>{
            if(item.value == this.formState.provCode){
              this.areaCodeData = item.cities;
            }
          })
          if(data.attachFileUrl){
            const fileList = [{
              url: data.attachFileUrl.split(',')[0],
            }]
            this.fileList = fileList;
          }
        } catch (error) {
          this.loading = false;
          console.log(error)
        }
      },
			onSubmit() {
        this.$refs.formRef.validateFields().then(async () => {
          if (this.formState.type == 2 && !this.fileList.length) {
            this.$message.warn('请上传附件');
            return;
          }

          if (this.formState.type == 2 && !this.formState.socialCreditCodeExpires && !this.longTime) {
            this.$message.warn('请选择统一社会信用证有效期');
            return;
          }

          if (this.formState.type == 2 && !this.formState.legalCertIdExpires) {
            this.$message.warn('请选择法人身份证有效期');
            return;
          }

          if (this.formState.type == 2) {
            this.formState.attachFileUrl = this.fileList[0].url;
          }
          const postData = JSON.parse(JSON.stringify(this.formState));
          delete postData.fileList;
          if (postData.type == 2) {
            postData.legalCertIdExpires = moment(postData.legalCertIdExpires).format('YYYYMMDD');
            postData.socialCreditCodeExpires = this.longTime ? '20991231' : moment(postData.socialCreditCodeExpires).format('YYYYMMDD');
          }
          postData.bankAcctType = postData.type == 1 ? 2 : 1;//1-对公；2-对私
          this.loading = true;
          try {
            let res;
            if (this.id) {
              postData.id = this.id;
              res = await updateCorpMember(postData);
            } else {
              res = await saveLedger(postData);
            }
            this.loading = false;
            if (res.code == 200) {
              this.$message.success(`${this.id?'编辑':'新增'}结算账户成功`);
              this.onBack();
            }
          } catch (error) {
            this.loading = false;
          }

        })
      },
            beforeUpload(file,fileList) {
                //console.log(file.type);
                const isZip = file.type === 'application/x-zip-compressed' || 'application/x-zip' || 'application/zip';
                if (!isZip) {
                    this.$message.error('上传文件格式不正确');
                }
                const isLt10M = file.size / 1024 / 1024 < 10;
                if (!isLt10M) {
                    this.$message.error('上传文件太大了');
                }
                return isZip && isLt10M;
            },
            handleChange(info) {
				if (!info.file.status) {
					this.fileList.splice(this.fileList.length - 1, 1);
				}
                if (info.file.status === 'done') {
                    this.fileList = info.fileList;
                    const $fileList = [...this.fileList];
                    this.fileList = $fileList.map(file => {
                        if (file.response) {
                            file.url = file.response.data.imgUrl;
                        }
                        return file;
                    });
                }
            },
            onVaildTime(value){
                this.formState.socialCreditCodeExpires = value;
                this.longTime = false;
            },
            onCertVaildTime(value){
                this.formState.legalCertIdExpires = value;
            },
            onFocus(){
                this.formState.socialCreditCodeExpires = '';
            },
            onChooseBank(value,obj){
                this.formState.bankCode = value;
            },
            selectBankCode(inputValue, option){
                return (
                    option.label.indexOf(inputValue.toLowerCase()) >= 0
                );
            },
            onChooseProvince(value,obj){
                this.formState.areaCode = '';
				this.areaCodeData = [];
                this.formState.provCode = value;
                this.areaCodeData = obj.cities;
            },
            selectProvCode(inputValue, option){
                return (
                    option.label.indexOf(inputValue.toLowerCase()) >= 0
                );
            },
            onChooseArea(value,obj){
                this.formState.areaCode = value;
            },
            selectAreaCode(inputValue, option){
                return (
                    option.label.indexOf(inputValue.toLowerCase()) >= 0
                );
            },
		}
	}
</script>

<style scoped>
	.ui-custom__btnInput {
		position: relative;
	}
	.ui-custom__btn {
		position: absolute;
		top: 0;
		right: 0;
		z-index: 9;
	}
    .ui-tip{
        color: #ff0000;
    }
</style>
